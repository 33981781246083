import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'wallet';

const translations = {
  title: 'InKind Wallet',
  btnSeeAllSupporters: 'See all supporters',
  active: 'Active',
  tabToRedeem: 'To Redeem',
  tabCardLinked: 'Card Activated',
  tabOther: 'Other',
  'toRedeem-emptyWalletLine1': 'There are currently no gifts to redeem in your Wallet.',
  'toRedeem-emptyWalletLine2':
    'Gifts activated to your payment card will appear in <1>Card Activated</1>. All other redeemed gifts will appear in Other.',
  'cardLinked-active-emptyWalletLine1': 'There are currently no gifts activated to your debit or credit card.',
  'cardLinked-active-emptyWalletLine2': 'Visit To Redeem to select gifts to link to your payment card.',
  'cardLinked-used-emptyWalletLine1': 'There are currently no used gifts activated to your debit or credit card.',
  'cardLinked-used-emptyWalletLine2': 'Visit Active to select activated gift to use.',
  'other-active-emptyWalletLine1': 'There are currently no active Visa cards or eGifts in your Wallet.',
  'other-active-emptyWalletLine2':
    'Visit To Redeem to select gifts to redeem, or visit <1>Card Activated</1> to view gifts that have been activated to your payment card.',
  'other-used-emptyWalletLine1': 'There are currently no Used Visa cards or eGifts in your Wallet.',
  'other-used-emptyWalletLine2': 'Visit Active to select gifts to use.',
  logoutBtn: 'Logout',
  supportersTitle: 'Supporter Details',
  backToWallet: 'Return to Wallet',
};
export default {
  [blockName]: translations,
  activityLog: {
    create: 'Purchased by supporter',
    'gift.create': 'Gift Created',
    'marked-as-used': 'Marked as Used.',
    'marked-as-unused': 'Marked as Unused.',
    'email-sent': 'Sent via email',
    'egift.create': 'Redeemed as eGift',
    'merge-create': 'Merged from other gifts',
    'swap-create': 'Swapped from other gift',
    'card.linked': 'Activated to payment card',
    'card.activated': 'Activated to payment card',
    'card.unlinked': 'Gift deactivated from payment card',
    'card.deactivated': 'Gift deactivated from payment card',
    'visa-digital.create': 'Redeemed as Digital Visa',
    'visa-physical.create': 'Redeemed as Physical Visa',
    'legacy.create': 'Redeemed as eGift',
    'legacy.create-visa': 'Redeemed as Digital Visa',
    'legacy.create-visa-physical': 'Redeemed as Physical Visa',
    'zelle.transfer': 'Transfer funds via Zelle',
    'linked-to-card': 'Gift Activated to payment card',
    'card.relinked': 'Gift Activated to payment card',
    'unlinked-from-card': 'Gift deactivated from payment card',
  },
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);

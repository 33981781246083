import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product, ProductVariation } from '@gik/core/models/gik/Product';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { formatCurrencyFallback } from '@gik/core/utils/formatCurrencyFallback';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { Input, InputCurrency } from '@gik/ui/Input';
import { Select } from '@gik/ui/Select';
import loadable from '@loadable/component';
import React from 'react';
import type { ButtonElement } from '../Button/ButtonProps';
import type { UISize, UIVariant } from '../types';
import { CheckoutType } from '@gik/models/gik/Product';
import type { GiftyaRedemtionMethod } from '@gik/platform-management-api-js';
import { GiftRedemptionMethod } from '@gik/platform-management-api-js';

const Format = loadable.lib(() => import('@gik/core/utils/format'));

// TODO: move to checkout package?

export interface ProductPriceProps extends UIComponent {
  /**
   * A WooCommerce product that will determine what to render
   */
  product: Product;

  /**
   * Style variant to pass to the control component
   */
  variant?: UIVariant;

  /**
   * Size to pass to the control component
   */
  size?: UISize;

  /**
   * passed to the control component
   */
  focus?: boolean;

  /**
   * passed to the control component
   */
  autoFocus?: boolean;

  /**
   * passed to the control component
   */
  disabled?: boolean;

  /**
   * passed to the control component
   */
  name?: string;

  /**
   * passed to the control component
   */
  value?: Partial<CartItem>;
  onChange?: (value: Partial<CartItem>) => void;

  /**
   * passed to the control component
   */
  tabIndex?: number;

  /**
   * passed to the form group component
   */
  label?: string;

  /**
   * passed to the form group component
   */
  labelBefore?: string;

  index?: number;

  /**
   * passed to the control component
   */
  onValueChange?: (value: boolean) => void;
  toggleValue?: OLProductType;

  waitForInitialValue?: boolean;
  compact?: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

function getToggleableValue(type: 'pg_min_value' | 'pg_max_value', product: Product, toggleValue?: OLProductType) {
  const variation: ProductVariation = product.variations.find(v =>
    v.metaData.find(m => m.key === 'pg_product_type' && m.value === toggleValue)
  );
  const metaDataEntry = variation?.metaData?.find(v => v.key === type);
  if (!metaDataEntry) throw `Could not find metadata entry for ${type}`;

  if (Array.isArray(metaDataEntry.value)) {
    throw `${type} value is not a string`;
  }

  return parseFloat(metaDataEntry.value);
}

function getToggleableMinValue(product: Product, toggleValue?: OLProductType) {
  return getToggleableValue('pg_min_value', product, toggleValue);
}

function getToggleableMaxValue(product: Product, toggleValue?: OLProductType) {
  return getToggleableValue('pg_max_value', product, toggleValue);
}

export function getProductMinValue(product: Product, toggleValue?: OLProductType) {
  return product.isToggleable ? getToggleableMinValue(product, toggleValue) : product.variations?.[0]?.minValue;
}

export function getProductMaxValue(product: Product, toggleValue?: OLProductType) {
  return product.isToggleable ? getToggleableMaxValue(product, toggleValue) : product.variations?.[0]?.maxValue;
}

/**
 * Displays the product price either as a fixed value (plaintext), an input field or a dropdown.
 */
export function ProductPrice({
  className,
  product,
  disabled,
  toggleValue,
  value,
  waitForInitialValue,
  compact,
  onChange,
  ...otherProps
}: ProductPriceProps): React.ReactElement {
  const bem = useBemCN('product-price');
  const minValue = React.useMemo(() => getProductMinValue(product, toggleValue), [product, toggleValue]);
  const maxValue = React.useMemo(() => getProductMaxValue(product, toggleValue), [product, toggleValue]);

  const egGftRedemptionMethod = (
    JSON.parse(product?.acf?.['gy_redemption_methods'] ?? '[]') as GiftyaRedemtionMethod[]
  )?.find?.(rm => rm.redemption_method == GiftRedemptionMethod.EGIFT);

  const isGYFixedVariations =
    product?.checkoutType == CheckoutType.GiftyaPlatform && egGftRedemptionMethod?.configuration?.['type'] == 'fixed';

  const fixedGYDenominations = (egGftRedemptionMethod?.configuration?.['denom'] as number[])?.map?.(
    denom => denom / 100
  );

  function renderInput() {
    return (
      <Format fallback={formatCurrencyFallback(product.price)}>
        {({ formatCurrency }) => {
          return (
            <>
              <InputCurrency
                disabled={disabled}
                value={value?.price?.toString() ?? ''}
                prepend="$"
                variant="default"
                sign="none"
                formatProps={{ decimalScale: 0 }}
                onValueBlur={newValue => {
                  onChange({
                    ...(value ?? {}),
                    price: newValue
                      ? product.checkoutType === 'donation'
                        ? parseFloat(newValue)
                        : parseInt(newValue)
                      : undefined,
                  });
                }}
                {...otherProps}
                placeholder="Enter Value"
                onKeyPress={(event: React.KeyboardEvent<ButtonElement>) => {
                  if (event.key === 'Enter') {
                    // prevent the default action of the enter key of the numeric input which is to submit the form
                    event.preventDefault();

                    const nextEl = document.querySelector('[name="anonymous-0"]') as HTMLInputElement;
                    if (nextEl) nextEl.focus();
                  }
                }}
                onValueChange={newValue => {
                  // note: when the value changes we want to temporarily allow float values
                  // only when the input loses focus should the float be formatted to an int
                  onChange({ ...(value ?? {}), price: newValue ? parseFloat(newValue) : undefined });
                }}
              />
              {minValue > 0 && maxValue > 0 && (
                <span {...bem('price-plain')}>
                  from {formatCurrency(minValue, { decimals: false })} to{' '}
                  {formatCurrency(maxValue, { decimals: false })}
                </span>
              )}
            </>
          );
        }}
      </Format>
    );
  }

  function renderGYSelect() {
    return (
      <Format fallback={formatCurrencyFallback(product.price)}>
        {({ formatCurrency }) => {
          const options: SelectOption[] = fixedGYDenominations
            .sort((a, b) => b - a)
            .map(price => ({
              value: price,
              label: formatCurrency(price, { decimals: false }),
            }));
          return (
            <Select
              placeholder="Please select an amount"
              disabled={disabled}
              options={options}
              {...bem('price-select')}
              value={value?.price?.toString()}
              onChange={(priceString: string) => {
                const price = parseInt(priceString);
                const selectedCartItem: CartItem = {
                  productSlug: product.slug,
                  productId: product.id,
                  groupedProductIds: product.groupedProductIds,
                  variationId: product.variations[0].id,
                  price,
                  quantity: 1,
                  name: product.name,
                  checkoutType: product.checkoutType,
                };
                onChange(selectedCartItem);
              }}
            />
          );
        }}
      </Format>
    );
  }

  function renderSelect() {
    return (
      <Format fallback={formatCurrencyFallback(product.price)}>
        {({ formatCurrency }) => {
          const options: SelectOption[] = product.variations
            .sort((a, b) => b.price - a.price)
            .map(variation => ({
              value: variation.id.toString(),
              label: formatCurrency(variation.price, { decimals: false }),
            }));
          return (
            <Select
              placeholder="Please select an amount"
              disabled={disabled}
              options={options}
              {...bem('price-select')}
              value={value?.variationId?.toString()}
              onChange={(newValue: string) => {
                const variationId = parseInt(newValue);
                const selectedCartItem: CartItem = {
                  productSlug: product.slug,
                  productId: product.id,
                  groupedProductIds: product.groupedProductIds,
                  variationId,
                  price: product.variations.find(variation => variation.id === variationId).price,
                  quantity: 1,
                  name: product.name,
                  checkoutType: product.checkoutType,
                };
                onChange(selectedCartItem);
              }}
            />
          );
        }}
      </Format>
    );
  }

  function renderPlainText() {
    return (
      <Format fallback={formatCurrencyFallback(product.price)}>
        {({ formatCurrencyReact }) => {
          return (
            <>
              <Input type="hidden" value={product.price.toString()} />
              <span {...bem('price')}>{formatCurrencyReact(product.price, { sup: true, decimals: true })}</span>
            </>
          );
        }}
      </Format>
    );
  }

  // detect what type of input to render
  let controlComponent: React.ReactNode = null;
  if ((waitForInitialValue && value?.price !== undefined) || !waitForInitialValue) {
    switch (product.type) {
      case 'input':
        controlComponent = renderInput();
        break;
      case 'select':
        controlComponent = renderSelect();
        break;
      default:
        controlComponent = renderPlainText();
    }
  }

  if (isGYFixedVariations) {
    controlComponent = renderGYSelect();
  }

  return <div {...bem(null, [{ compact }], className)}>{controlComponent}</div>;
}
